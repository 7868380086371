import React from "react"

import './modal.css';
import CancelRound from '../../images/assets/cancel-round.svg'
import IconButton from '../Layout/Button/IconButton'
import NextIcon from '../../images/assets/next-icon.svg'
import PreviousIcon from '../../images/assets/previous-icon.svg'
import styled from 'styled-components';


const Modal = ({ handleClose, handleNext, handlePrevious, show, children }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    return (
        <div className={showHideClassName}>
            <CancelContainer >
                <IconButton size="25px" padding="5px" onClick={handleClose}>
                    <CancelRound />
                </IconButton>
            </CancelContainer>

            <div className="slider-arrow">
                <div className="slider-arrow-btns slider-arrow-btns-prev">
                    <IconButton size="35px" padding="5px" onClick={handlePrevious}>
                        <PreviousIcon />
                    </IconButton>
                </div>
                <div className="slider-arrow-btns slider-arrow-btns-next">
                    <IconButton size="35px" padding="5px" onClick={handleNext}>
                        <NextIcon />
                    </IconButton>
                </div>
            </div>

            <div className="modal-main">
                {children}
            </div>

        </div>
    );
};


export default Modal

const CancelContainer = styled.div`
    display: flex;
    justify-content: flex-end; 
    padding-top: 30px; 
    padding-right: 30px;

    @media (max-width: 900px) {
        padding-top: 5px; 
        padding-right: 5px;
    }
 
`;