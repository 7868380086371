// import React from "react"
// import styled from 'styled-components'

// const Grid2Auto = ({children }) => {
//   return (
//     <>
//         <Grid>
//             {children}
//         </Grid>
//     </>
//   )
// } 

// export default Grid2Auto

// const Grid = styled.div`
//     display: grid;
//     grid-template-columns: auto auto;
//     column-gap: 40px;
    
//     @media (max-width: 800px) {
//       grid-template-columns: 100%;
//       grid-template-rows: auto auto;
//       justify-items: center;
//     }

// `;

import styled from 'styled-components';

const Grid2Auto = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 40px;

  @media (max-width: 800px) {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    justify-items: center;
  }

`;

export default Grid2Auto;


