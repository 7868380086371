import React from "react"

import { graphql } from 'gatsby'
import * as Markdown from 'react-markdown';
import styles from './CarDetails.module.css';


import Layout from "../components/Layout/Layout"
import PageHeaderListing from '../components/Layout/PageHeader/PageHeaderListing'
import SectionPageMargin from '../components/Layout/Section/SectionPageMargin'

import Paragraph1 from '../components/Type/Paragraph1'

import Grid2Auto from '../components/Layout/Grid2/Grid2Auto'
import GridItem from '../components/Layout/GridItem/GridItem'

import Img from "gatsby-image"
import SEO from "../components/Seo/Seo"

import styled from 'styled-components'

import Highlight from '../components/Listings/Highlight'
import Information from '../components/Listings/Information'
import SellingSoon from '../components/SellingSoon/SellingSoon'
import ListingForm from '../components/Listings/ListingEquiryForm'
import DepositForm from '../components/Listings/DepositForm'
import BackgroundImage from 'gatsby-background-image'

import PlusRound from '../images/assets/plus-round.svg'


import Modal from '../components/GalleryModal/Modal'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



const CarDetailsPage = ({ location, data }) => {

  // Modal stuff.
  const [modalOpen, setModalOpen] = React.useState(false);
  const [hideForDeposit, setHideForDeposit] = React.useState(false);

  //const [intialSlide, setInitialSlide] = React.useState(0);

  const sliderRef = React.useRef();
  //const modalCloseTimeout = 300;
  const closeModal = () => {
    document.body.style.overflow = 'unset';
    document.body.style.paddingRight = '0px';
    setModalOpen(false);
    //setTimeout(() => navigate(`/`), modalCloseTimeout);
  };


  
// Slider settings
var settings = {
  accessibility: false,
  dots: false,
  arrows: false,
  centerMode: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  lazyLoad: 'progressive'
};



  const openModal = (slideId) => {
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = '15px';

    // open slider on same images as thumbnail click
    var slideIndex = 0;
    for(var i=0; i< pageData.photos.length; i++){
      if(pageData.photos[i].contentful_id === slideId) {
        slideIndex = i;
        break;
      }
    }
    
    sliderRef.current.slickGoTo(slideIndex);
    setModalOpen(true);
  }

  const handleNext = () => {
    sliderRef.current.slickNext();
  }

  const handlePrevious = () => {
    sliderRef.current.slickPrev();
  }

  const pageData = data.contentfulCarListing;
  //console.log(data);

  const ppsrCertificate = pageData.ppsrCertificate ? <Information action="Download" link={pageData.ppsrCertificate.file.url} title="PPSR Certificate" type="(PDF)" /> : "";
  const redbookInspection = pageData.redbookInspection ? <Information action="View" link={pageData.redbookInspection} title="Redbook Inspection" type="" /> : "";
  const carFactsReport = pageData.carFactsReport ? <Information action="Download" link={pageData.carFactsReport.file.url} title="CarFacts Report" type="(PDF)" /> : "";
  const carSales = pageData.carSalesAdId ? <Information action="View" link={pageData.carSalesAdId} title="Carsales Listing" type="" /> : "";

  let price = pageData.price;
  let forSaleText = "for sale";
  if (pageData.sold) {
    price = "Sold";
    forSaleText = "";
  }

  const carData = {
    carName: pageData.carName,
    text: pageData.description.description,
    price: pageData.price,
    odometer: pageData.odometer,
    year: pageData.buildYear,
    state: pageData.location,
    page: location
  }

  const carSlides = pageData.photos.map((item, i) => 
    <Slide >
      <SlideContents>
        <Img
          fluid={item.fluid}
          imgStyle={{ objectFit: "contain" }}
          style={{
            // backgroundSize: 'cover',
            // backgroundPosition: 'center',
            // backgroundRepeat: 'no-repeat',
            width: '100%',
            height: '100%',
          }}
           />
      </SlideContents>
    </Slide>
	);


  return (
    <Layout>

      <SEO title={pageData.carName + " " + forSaleText + " #" + pageData.pipedriveDealId}
        description={"Car details page for " + pageData.carName + " " + forSaleText + " " + pageData.pipedriveDealId}
        location={location}
        image={pageData.photos[0].fluid.src}
      />




      <Modal show={modalOpen} handleClose={closeModal} handleNext={handleNext} handlePrevious={handlePrevious}>
        <div style={{ height: '100%', width: '100%' }}>
          <Slider ref={sliderRef} {...settings} style={{ height: '100%', width: '100%' }}>
            {carSlides}
          </Slider> 
        </div>
      </Modal>


      <PageHeaderListing
        title={pageData.carName + " " + forSaleText}
        text={pageData.description.description}
        price={price}
        odometer={pageData.odometer}
        year={pageData.buildYear}
        location={pageData.location}
      />


      <SectionPageMargin marginBottom="40px">
        <HeroContainer>
          <MainImage className={`${styles.bgimage}`} onClick={() => openModal(pageData.listingDetailHeroImages[0].contentful_id)}>

            <BackgroundImage
              fluid={pageData.listingDetailHeroImages[0].fluid}
              style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                width: '100%',

              }}
            />
            <Plus>
              <PlusRound />
            </Plus>
          </MainImage>
        </HeroContainer>
        <HeroContainer>
        {pageData.listingDetailHeroImages[1] ? <OtherImage className={`${styles.bgimage}`} onClick={() => openModal(pageData.listingDetailHeroImages[1].contentful_id)}>

            <BackgroundImage
              fluid={pageData.listingDetailHeroImages[1].fluid}
              style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                width: '100%',
              }}
            />
            <Plus>
              <PlusRound />
            </Plus>
          </OtherImage>  : ""}
          {pageData.listingDetailHeroImages[2] ?<OtherImage className={`${styles.bgimage}`} onClick={() => openModal(pageData.listingDetailHeroImages[2].contentful_id)}>
            <BackgroundImage
              fluid={pageData.listingDetailHeroImages[2].fluid}
              style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                width: '100%',
              }}
            />
            <Plus>
              <PlusRound />
            </Plus>
          </OtherImage> : ""}
          {pageData.listingDetailHeroImages[3] ? <OtherImage className={`${styles.bgimage}`} onClick={() => openModal(pageData.listingDetailHeroImages[3].contentful_id)}>

            <BackgroundImage
              fluid={pageData.listingDetailHeroImages[3].fluid}
              style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                width: '100%',
              }}
            />
            <Plus>
              <PlusRound />
            </Plus>
          </OtherImage> : ""}
        </HeroContainer>
      </SectionPageMargin>




      <SectionPageMargin marginBottom="40px">
        <Highlights>
          <Highlight title="Odometer" value={pageData.odometer} />
          <Highlight title="Body Type" value={pageData.bodyStyle} />
          <Highlight title="Transmission" value={pageData.transmission} />
          <Highlight title="Engine" value={pageData.engine} />
        </Highlights>
      </SectionPageMargin>

      <SectionPageMargin>
        <Grid2Auto >
          <GridItem order="1" >
            <strong>
              This {pageData.carName} {!pageData.sold? "is" : "was"} for sale as Private Sale, with Summon’s concierge service
             </strong>

            <Paragraph1>
              <Markdown source={pageData.longDescription.longDescription} />
            </Paragraph1>

            <Paragraph1>
              We are an independent prestige car agency,  this car is being sold as a private sale
              with our concierge service. This provides you with many of the benefits of buying
              from a Dealer such as;
              <ul>
                <li>Professional service from our concierge team</li>
                <li>Competitive tailored finance package</li>
                <li>Australia-wide delivery available</li>
                <li>Trade-ins are welcomed</li>
                <li>PPSR and Guaranteed Title</li>
              </ul>
            </Paragraph1>

            <Paragraph1>
              <strong>More information about this car</strong>
            </Paragraph1>

            <MoreInformation>
              <div >
                {ppsrCertificate}
                {redbookInspection}
                {carFactsReport}
                {carSales}
                <br />
                <Img fixed={data.ppsrImage.childImageSharp.fixed} /><br />
              </div>

            </MoreInformation>

          </GridItem>
          <GridItem order="2">

            {!pageData.sold && 
              <DepositForm 
                pipedriveDealId={pageData.pipedriveDealId}
                price={pageData.price}
                carName={pageData.carName}
                location={pageData.location}
                listingImage={pageData.listingIndexImage.file.url} 
                sold={pageData.sold}
                setHideForDeposit={setHideForDeposit}
                carData={carData}
            />}
          
           
            {!hideForDeposit && <ListingForm
              pipedriveDealId={pageData.pipedriveDealId}
              price={pageData.price}
              carName={pageData.carName}
              location={pageData.location}
              listingImage={pageData.listingIndexImage.file.url} 
              sold={pageData.sold}
            />}

          </GridItem>
        </Grid2Auto>
      </SectionPageMargin>

      <SellingSoon pipedriveDealId={pageData.pipedriveDealId} />



    </Layout>
  )
}

export default CarDetailsPage

const Highlights = styled.div`
  display: flex;
  @media (max-width: 800px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    grid-template-rows: auto auto;
  }

`;

const Slide = styled.div`
  height: 80vh; 
  width: 100%;
`;

const SlideContents = styled.div`
  width: 100%;
  height: 100%;
`;





const Plus = styled.span`
  position: absolute;
  display: block;
  left: 10px;
  bottom: 10px;
  padding: 2px;
  width: 25px;
  height: 25px;

`;


const MoreInformation = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
`;


const HeroContainer = styled.div`
  display: flex;
`;

const MainImage = styled.div`
  display: flex;
  height: calc(100vw / 2);
  max-height: 400px;
  flex-basis: 100%

`;


const OtherImage = styled.div`
  display: flex;
  height: 200px;
  flex-basis: 33.333%;
`;




export const PageQuery = graphql`
  query ($id: String!) {
    contentfulCarListing(contentful_id: {eq: $id}) {
      description {
        description
      }
      bodyStyle
      buildYear
      carFactsReport {
        file {
          url
        }
      }
      carName
      carSalesAdId
      engine
      fuelType
      listingDetailHeroImages {
        contentful_id
        fluid (maxWidth: 1024) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      photos {
        contentful_id
        fluid (maxWidth: 1500) {
        ...GatsbyContentfulFluid_noBase64
        }
      }
      location
      longDescription {
        longDescription
      }
      listingIndexImage {
        file {
          url
        }
      }
      odometer
      pipedriveDealId
      price
      sold
      transmission
      vin
      slug
      ppsrCertificate {
        file {
          url
        }
      }
      redbookInspection
    }

    ppsrImage: file(relativePath: { eq: "ppsr/ppsr.png" }) {
      childImageSharp {
          fixed(width: 173, height: 80) {
          ...GatsbyImageSharpFixed
          }
      }
      }

  }`
