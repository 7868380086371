import React, { Component } from 'react';
import SectionPageMargin from '../Layout/Section/SectionPageMargin'
import Grid2 from '../Layout/Grid2/Grid2'
import GridItem from '../Layout/GridItem/GridItem'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import FormControl from '../Form/FormControl'
//import ActionControl from '../Form/ActionControl'
import Input from '../Form/Input'
import InputError from '../Form/InputError'
import Button from '../Layout/Button/Button'
import Paragraph1 from '../Type/Paragraph1';
import styled from 'styled-components'
//import { Link } from "gatsby"
import Heading1 from '../Type/Heading1'
import tick from './tick.png';
import fbTrack from '../FacebookTrack/FacebookTrack';

class SellingSoon extends Component {

    constructor(props) {
        super(props);

        this.nameEl = React.createRef();
        this.carEl = React.createRef();
        this.emailEl = React.createRef();
        this.mobileEl = React.createRef();

        this.state = {
            formSubmissionSuccessful: null,
            formError: "",
            nameError: "",
            carError: "",
            emailError: "",
            mobileError: "",
        }

        this.nameValidation = ["nameError",
            {
                required: "Enter your first name and last name",
                twoparts: "Enter your first name and last name",
            }];

        this.carValidation = ["carError",
            {
                required: "Enter the Make, Model, Year of your car",
                threeparts: "Enter the Make, Model, Year of your car",
            }];

        this.emailValidation = ["emailError",
            {
                required: "Enter your email address",
                email: "Enter a valid email address"
            }];

        this.mobileValidation = ["mobileError",
            {
                required: "Enter your mobile number",
                mobile: "Enter a valid mobile number"
            }];

        this.submitHandler = this.submitHandler.bind(this);
    }

    handleOptionChange = changeEvent => {
        this.setState({
            selectedOption: changeEvent.target.value
        });
        this.validate(changeEvent.target.value, this.buySellValidation);
    };

    submitHandler = (event) => {

        event.preventDefault(); // stop form loading new page

        const name = this.nameEl.current.value;
        const car = this.carEl.current.value;
        const email = this.emailEl.current.value;
        const mobile = this.mobileEl.current.value;

        var errors = 0;
        errors += this.validate(name, this.nameValidation);
        errors += this.validate(car, this.carValidation);
        errors += this.validate(email, this.emailValidation);
        errors += this.validate(mobile, this.mobileValidation);

        if (errors !== 0) return errors;

       
        trackCustomEvent({
            category: "Conversion",
            action: "Form Submission",
            label: "Quick Contact Car details"
        });
        
        fbTrack('track', 
                'Lead', 
                {content_category: "Selling Soon", 
                 content_name: "Component", 
                 currency: 'AUD', 
                 value: 1.00}
        );

        let formBody = {
            name: name,
            car: car,
            email: email,
            mobile: mobile,
            stock: this.props.pipedriveDealId,
        }

        this.setState({ formSubmissionSuccessful: true });

        fetch(`https://carxapi.com/website/car-details-contact`, {
        //fetch(`http://172.16.16.210:8081/website/car-details-contact`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(formBody),
            headers: {
                // 'Capatcha': this.state.capatchaValue,
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status !== 200 && res.status !== 201) {
                this.setState({ formError: "Unfortunately we were unable to send your request." })
                // window.grecaptcha.reset();

                throw new Error('Failed!');
            }
            return res.json();
        })
            .then(resData => {
                // bit too long to wait actual emails sent 
                this.setState({ formSubmissionSuccessful: true });
                // if  this.carType == other move to payment
                //navigate("/contact-confirmation/");
            })
            .catch(err => {
                console.log(err);
            });

    };

    validate = (value, validation) => {
        var fieldValue = value;
        var errMessage = "";

        if ('email' in validation[1]) {
            let emailValid = fieldValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            if (!emailValid) {
                errMessage = validation[1].email;
            }
        }

        if('mobile' in validation[1]) {
            var reMobile = new RegExp("^((61|\\+61)?\\s?)((04[0-9]{2}|4[0-9]{2})\\s?)([0-9]{3}\\s?[0-9]{3}|[0-9]{2}\\s?[0-9]{2}\\s?[0-9]{2})$");
            if(!reMobile.test(fieldValue)) {
                errMessage = validation[1].mobile;
            }
        }

        if ('required' in validation[1]) {
            if (fieldValue.trim().length === 0) {
                errMessage = validation[1].required;
            }
        }
        
        if ('twoparts' in validation[1]) {
            let parts = fieldValue.split(" ");
            if (parts.length < 2) {
                errMessage = validation[1].twoparts;
            } else if (parts[1].length < 2) {
                errMessage = validation[1].twoparts;
            }
        }

        if ('threeparts' in validation[1]) {
            let parts = fieldValue.split(" ");
            if (parts.length < 3) {
                errMessage = validation[1].threeparts;
            } 
        }



        this.setState({ [validation[0]]: errMessage });
        return (errMessage === "") ? 0 : 1;
    }

    render() {

        const confirmationMessage = (
            <>
                 <Paragraph1>
                     <strong>All Done!</strong>
                </Paragraph1>  
                <Paragraph1>
                    Thank you for your enquiry. One of our car sales consultants will be in contact shortly.
                </Paragraph1>
            </>
        );

       
        const pageHTML = (

            <form onSubmit={this.submitHandler}>
                <FormControl>
                    <LabelContainer>
                        <LabelNote for="name">Your name</LabelNote>
                    </LabelContainer>
                    <Input
                        type="text"
                        id="name"
                        onBlur={(e) => this.validate(e.target.value, this.nameValidation)}
                        ref={this.nameEl}
                    />
                    <InputError>{this.state.nameError}</InputError>
                </FormControl>

                <FormControl>
                    <LabelContainer>
                        <LabelNote for="car">Car details (Make, Model, Year)</LabelNote>
                    </LabelContainer>
                    <Input
                        type="text"
                        id="car"
                        onBlur={(e) => this.validate(e.target.value, this.carValidation)}
                        ref={this.carEl}
                    />
                    <InputError>{this.state.carError}</InputError>
                </FormControl>

                <FormControl>
                    <LabelContainer>
                        <LabelNote for="email">Email</LabelNote>
                    </LabelContainer>
                    <Input
                        type="email"
                        id="email"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$"
                        onBlur={(e) => this.validate(e.target.value, this.emailValidation)}
                        ref={this.emailEl} />
                    <InputError>{this.state.emailError}</InputError>
                </FormControl>

                <FormControl>
                    <LabelContainer>
                        <LabelNote for="mobile">Mobile</LabelNote>
                    </LabelContainer>
                    <Input
                        type="text"
                        id="mobile"
                        onBlur={(e) => this.validate(e.target.value, this.mobileValidation)}
                        ref={this.mobileEl}
                    />
                    <InputError>{this.state.mobileError}</InputError>
                </FormControl>


                <SubmitContainer>
                    <div style={{width: '140px', color: '#3988c4', fontSize: '.9rem', fontWeight: '600'}}>
                        <img src={tick} alt="tick" style={{float: 'right', width: '30px', height: '30px', marginTop: '5px'}} />
                        Discount code <br />
                        LD250 applied
                    </div>
                    <div>
                        <Button type="submit">Submit</Button>  
                    </div>
                    <InputError>{this.state.FormError}</InputError>
                </SubmitContainer>
           

            </form>
        )

        return (
            <SectionPageMargin bgColor="#f8f8f9" marginBottom='0px'>
                <Grid2 style={{paddingTop: '40px', paddingBottom: '80px'}}>
                    <GridItem order="1">
                        <Heading1>Selling Soon?</Heading1>
                        <Paragraph1>
                            For more information about how we can help you to sell your prestige 
                            car, and to request a free valuation of your car, please get in touch today.
                        </Paragraph1>
                        <Paragraph1>
                            Quote discount code LD250 for $250 off our standard commission fee. 
                        </Paragraph1> 
                    </GridItem>
                    <GridItem order="2">
                        {this.state.formSubmissionSuccessful && confirmationMessage}
                        {!this.state.formSubmissionSuccessful && pageHTML}
                    </GridItem>
                </Grid2>
            </SectionPageMargin> 
        );
    }

}

export default SellingSoon;

const SubmitContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 370px;
`;

const LabelContainer = styled.div`
    margin-bottom: 10px;
`;

const LabelNote = styled.label`
    font-weight: 400;
    font-size: 1rem;
`;


