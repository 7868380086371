import React, { Component } from 'react';
//import ReCAPTCHA from "react-google-recaptcha";
import { loadStripe } from "@stripe/stripe-js";
//import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import FormControl from '../Form/FormControl';
import ActionControl from '../Form/ActionControl';

import Input from '../Form/Input';
//import TextArea from '../Form/TextArea'
//import Checkbox from '../Form/Checkbox';
import InputError from '../Form/InputError';
import Button from '../Layout/Button/Button';
import CancelButton from '../Layout/Button/CancelButton';
//import Paragraph1 from '../Type/Paragraph1';
import styled from 'styled-components';
//import ContactTracker from '../ContactTracker/ContactTracker';
import Label from '../Form/Label'
import bullet from './tick.png'
//import fbTrack from '../FacebookTrack/FacebookTrack';

//import CancelRound from '../../images/assets/cancel-round.svg'
//import IconButton from '../Layout/Button/IconButton'
//import Grid2Auto from '../Layout/Grid2/Grid2Auto'
//import GridItem from '../Layout/GridItem/GridItem'

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)
const price_id = "" + process.env.GATSBY_STRIPE_DEPOSIT_PRODUCT;

const redirectToCheckout = async (id,email) => {
  //event.preventDefault();
  const stripe = await stripePromise
  const { error } = await stripe.redirectToCheckout({
    lineItems: [{ price: price_id, quantity: 1 }],
    mode: "payment",
    clientReferenceId: id,
    customerEmail: email,
    successUrl: `https://www.summon.com.au/deposit-success`,
    cancelUrl: `https://www.summon.com.au/deposit-cancel`,
  
  })
  if (error) {
    console.warn("Error:", error)
  }
}



class DepositForm extends Component {

    constructor(props) {
        super(props);

        this.nameEl = React.createRef();
        this.emailEl = React.createRef();
        this.mobileEl = React.createRef();


        //this.buySellEl = React.createRef();
        //this.recaptchaRef = React.createRef();

        //this.carType = props.carType;
        //this.closePop = props.closePop;

        this.state = {
            formSubmissionSuccessful: false,
            name: "",
            mobile: "",
            email: "",
            formError: "",
            nameError: "",
            mobileError: "",
            emailError: "",
            processing: false,
            processingError: null,
            showForm: false,
        }

        this.nameValidation = ["nameError",
            { required: "Enter your name" }];

        this.emailValidation = ["emailError",
            {
                required: "Enter your email address",
                email: "Enter a valid email address"
            }];

        this.mobileValidation = ["mobileError",
            {
                required: "Enter your mobile number",
                mobile: "Enter a valid mobile number"
            }];


        this.submitHandler = this.submitHandler.bind(this);
        this.openForm = this.openForm.bind(this);
        this.closeForm = this.closeForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.setHideForDeposit = this.props.setHideForDeposit;
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    openForm() {
        this.setState({ showForm: true });
        this.setHideForDeposit(true);
    }

    closeForm() {
        this.setState({ showForm: false });
        this.setHideForDeposit(false);
    }



    submitHandler = (event) => {

        event.preventDefault(); // stop form loading new page

        const name = this.state.name
        const mobile = this.state.mobile
        const email = this.state.email


        var errors = 0;
        errors += this.validate(name, this.nameValidation);
        errors += this.validate(mobile, this.mobileValidation);
        errors += this.validate(email, this.emailValidation);

        if (errors !== 0) return errors;

        /*
            trackCustomEvent({
                category: "Conversion",  // how to we want to track this in GA?
                action: "Form Submission",
                label: "Lising enquiry"
            });
    
            fbTrack('track', 
                    'Lead', 
                    {content_category: "Lising enquiry", 
                        content_name: "Component", 
                        currency: 'AUD', 
                        value: 1.00}
            );
        */



        let formBody = {
            name: name,
            mobile: mobile,
            email: email,
            dealID: this.props.pipedriveDealId,
            car: this.props.carName,
            price: this.props.price,
            state: this.props.location,
            listingImage: this.props.listingImage,
            sold: this.props.sold,
            carData: this.props.carData,
        }


        this.setState({ formSubmissionSuccessful: true, processing: true, processingError: null }); // shoud add a spinner

        fetch(`https://carxapi.com/website/deposit`, {
        //fetch(`http://172.16.16.210:8081/website/deposit`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(formBody),
            headers: {
                // 'Capatcha': this.state.capatchaValue,
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status !== 200 && res.status !== 201) {
                this.setState({formError: "Unfortunately we were unable to send your request." });
                //window.grecaptcha.reset();

                throw new Error('Failed!');
            }
            return res.json();
        })
            .then(resData => {
              
                if (resData.error) {

                    this.setState({
                        processingError: resData.error,
                        formSubmissionSuccessful: true,
                        processing: false,
                        formError: "Unfortunately we were unable to send your request."
                    });
                }
                if (resData.deposit) {
                    redirectToCheckout(resData.deposit, email);
                   
                    // this.setState({
                    //     processingError: null,
                    //     formSubmissionSuccessful: true,
                    //     processing: false
                    // });
                }
            })
            .catch(err => {
                console.log(err);
            });

    };

    validate = (value, validation) => {
        var fieldValue = value;
        var errMessage = "";

        if ('email' in validation[1]) {
            let emailValid = fieldValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            if (!emailValid) {
                errMessage = validation[1].email;
            }
        }

        if ('mobile' in validation[1]) {
            var reMobile = new RegExp("^((61|\\+61)?\\s?)((04[0-9]{2}|4[0-9]{2})\\s?)([0-9]{3}\\s?[0-9]{3}|[0-9]{2}\\s?[0-9]{2}\\s?[0-9]{2})$");
            if (!reMobile.test(fieldValue)) {
                errMessage = validation[1].mobile;
            }
        }

        if ('required' in validation[1]) {
            if (fieldValue.trim().length === 0) {
                errMessage = validation[1].required;
            }
        }

        this.setState({ [validation[0]]: errMessage });
        return (errMessage === "") ? 0 : 1;
    }

    render() {


        // const errorMessage = (
        //     <>
        //         <Paragraph1>
        //             <strong>OPPS! Something went wrong.</strong>
        //         </Paragraph1>

        //         <Button type="button" onClick={this.resetForm}>Try Again</Button>
        //     </>
        // );

        // let confirmationMessage = (
        //     <>
        //         <Paragraph1>
        //             Thank you for your enquiry.
        //         </Paragraph1>
        //     </>
        // );

       /* if (this.state.processingError) confirmationMessage = errorMessage; */



        // const processingMessage = (
        //     <>
        //         <Paragraph1>
        //             Submitting....
        //         </Paragraph1>
        //     </>);


        const modalHTML = (<>

            <ul>
                <LI>Fully refundable</LI>
                <LI>Subject to final inspection</LI>
                <LI>Buy the car online</LI>
                <LI>Delivery or Click-and-Collect available*</LI>
            </ul>

            <div>
                <form onSubmit={this.submitHandler}>
                    <FormControl>
                        <LabelContainer>
                            <Label for="name">Your name</Label>
                        </LabelContainer>
                        <Input
                            type="text"
                            id="name"
                            name="name"
                            onBlur={(e) => this.validate(e.target.value, this.nameValidation)}
                            onChange={this.handleInputChange}
                            ref={this.nameEl}
                            aria-label="Full Name"
                        />
                        <InputError>{this.state.nameError}</InputError>
                    </FormControl>

                    <FormControl>
                        <LabelContainer>
                            <Label for="mobile">Mobile</Label>
                        </LabelContainer>
                        <Input
                            type="text"
                            id="mobile"
                            name="mobile"
                            onBlur={(e) => this.validate(e.target.value, this.mobileValidation)}
                            onChange={this.handleInputChange}
                            ref={this.mobileEl}
                            aria-label="Mobile"
                        />
                        <InputError>{this.state.mobileError}</InputError>
                    </FormControl>

                    <FormControl>
                        <LabelContainer>
                            <Label for="email">Your email</Label>
                        </LabelContainer>
                        <Input
                            type="email"
                            id="email"
                            name="email"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$"
                            onBlur={(e) => this.validate(e.target.value, this.emailValidation)}
                            onChange={this.handleInputChange}
                            aria-label="Email"
                        />
                        <InputError>{this.state.emailError}</InputError>
                    </FormControl>

                    <ActionControl>
                        <div style={{display: 'flex', justifyContent:'space-between'}}>
                            <div>
                                <CancelButton type="button" onClick={this.closeForm}>Cancel</CancelButton>
                            </div>
                            <div>
                            {!this.state.processing && <Button type="submit" >Next</Button>}
                            {this.state.processing && <Button color="#CCCCCC" type="button" disabled={true}>...</Button>}
                               
                            </div>
                        </div>
                        <InputError style={{marginTop: '10px'}}>{this.state.formError}</InputError>
                       
                    </ActionControl>
                </form>
            </div>
            <Note>* Click-and-collect availibility subject to location</Note>
        </>)

        return (

            <div style={{ width: '300px', backgroundColor: '#f8f8f9', padding: '20px', marginBottom: '30px' }}>

                <Title>Reserve this car</Title>
                <p>Place a refundable $1,000 deposit to reserve this car today.</p>
                <p>This car will be taken off the market, and we will phone to arrange settlement and delivery of the vehicle.</p>

                {!this.state.showForm &&
                    <Button type="button" onClick={this.openForm}>More Info</Button>
                }

                {this.state.showForm && modalHTML}


                {/* {!this.state.formSubmissionSuccessful && !this.state.processing && pageHTML}
                {!this.state.formSubmissionSuccessful && this.state.processing && processingMessage}
                {this.state.formSubmissionSuccessful && !this.state.processing && confirmationMessage} */}
            </div>

        );
    }

}

export default DepositForm;



const Title = styled.h2`
    font-weight: 600;
    font-size: 1.2rem;
    margin-right: 10px;
`

const LabelContainer = styled.div`
    margin-bottom: 0px;
`;

// const LabelBold = styled.label`
//     font-weight: 600;
//     margin-right: 10px;
// `;


const Note = styled.div`
    font-size: .8rem;
`;

const LI = styled.li`
 
	margin-left: -30px;
    padding: 5px;
	list-style: none;
	margin-bottom: 10px;
	// line-height: 2rem;
	position: relative;
    padding-left: 30px;

	&:before {
		content: '';
		width: 25px;
		height: 25px;
		position: absolute;
		background-image: url('${bullet}');
		background-size: cover;
		background-position: center;
		left: 0;
		top: 4px;
	
	}

`;

