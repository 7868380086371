import styled from 'styled-components'

const Checkbox = styled.input.attrs(props => ({
  type: props.type || 'checkbox'
}))`
  outline: none;
  transform:scale(1.5, 1.5);

  // font-size: 2rem;
  // background-color: white;
  // color:  black;
  // padding: 5px 5px 5px 12px;
  // border: #CCCCCC solid 1px;
  // margin-bottom: 0.5rem;
`
export default Checkbox;
