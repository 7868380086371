import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import FormControl from '../Form/FormControl';
import ActionControl from '../Form/ActionControl';

import Input from '../Form/Input';
import TextArea from '../Form/TextArea'
import Checkbox from '../Form/Checkbox';
import InputError from '../Form/InputError';
import Button from '../Layout/Button/Button';
import Paragraph1 from '../Type/Paragraph1';
import styled from 'styled-components';
//import ContactTracker from '../ContactTracker/ContactTracker';
import Label from '../Form/Label'
import fbTrack from '../FacebookTrack/FacebookTrack';

class ListingForm extends Component {

    constructor(props) {
        super(props);

        this.nameEl = React.createRef();
        this.emailEl = React.createRef();
        this.mobileEl = React.createRef();
        this.commentEl = React.createRef();

        //this.buySellEl = React.createRef();
        this.recaptchaRef = React.createRef();

        //this.carType = props.carType;
        //this.closePop = props.closePop;

        this.state = {
            formSubmissionSuccessful: false,
            name: "",
            mobile: "",
            email: "",
            comment: "",
            formError: "",
            nameError: "",
            mobileError: "",
            emailError: "",
            commentError: "",
            isVerified: false,
            capatchaValue: "",
            selectedOption: "",
            processing: false,
            processingError: null,
        }

        this.nameValidation = ["nameError",
            { required: "Enter your name" }];

        this.emailValidation = ["emailError",
            {
                required: "Enter your email address",
                email: "Enter a valid email address"
            }];

        this.mobileValidation = ["mobileError",
            {
                required: "Enter your mobile number",
                mobile: "Enter a valid mobile number"
            }];


        this.submitHandler = this.submitHandler.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if(name ==='valuation') {
            this.setState({
                [name]: target.checked
            });

        } else {
            this.setState({
                [name]: value
            });
        }
        
    }


    // handleOptionChange = changeEvent => {
    //     this.setState({
    //         selectedOption: changeEvent.target.value
    //     });
    //     this.validate(changeEvent.target.value, this.buySellValidation);
    // };


    onChangeCaptcha = (value) => {
        if (value !== null) {
            this.setState({ isVerified: true, capatchaValue: value });
        } else {
            this.setState({ isVerified: false, capatchaValue: null, formError: "" });
        }
    }

    resetForm = () => {
        this.setState({
            isVerified: false,
            capatchaValue: null,
            formError: "",
            processingError: null,
            formSubmissionSuccessful: false,
            processing: false
        })
    }

    submitHandler = (event) => {

        event.preventDefault(); // stop form loading new page

        const name = this.state.name
        const mobile = this.state.mobile
        const email = this.state.email
        const comment = this.state.comment;
        const valuation = this.state.valuation;

        var errors = 0;
        errors += this.validate(name, this.nameValidation);
        errors += this.validate(mobile, this.mobileValidation);
        errors += this.validate(email, this.emailValidation);

        if (errors !== 0) return errors;

        if (!this.state.isVerified) return; // reCaptacha

        if(valuation) {

            trackCustomEvent({
                category: "Conversion",  // how to we want to track this in GA?
                action: "Form Submission",
                label: "Lising enquiry"
            });
    
            fbTrack('track', 
                    'Lead', 
                    {content_category: "Lising enquiry", 
                     content_name: "Component", 
                     currency: 'AUD', 
                     value: 1.00}
            );

        }
   

        let formBody = {
            name: name,
            mobile: mobile,
            email: email,
            comment: comment,
            dealID: this.props.pipedriveDealId,
            car: this.props.carName,
            price: this.props.price,
            state: this.props.location,
            valuation: valuation,
            listingImage: this.props.listingImage,
            sold: this.props.sold
        }


        this.setState({ formSubmissionSuccessful: true, processing: false, processingError: null }); // shoud add a spinner

        fetch(`https://carxapi.com/website/listing-enquiry`, {
        //fetch(`http://172.16.16.210:8081/website/listing-enquiry`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(formBody),
            headers: {
                'Capatcha': this.state.capatchaValue,
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status !== 200 && res.status !== 201) {
                this.setState({ formError: "Unfortunately we were unable to send your request." })
                window.grecaptcha.reset();

                throw new Error('Failed!');
            }
            return res.json();
        })
            .then(resData => {

                if (resData.error) {

                    this.setState({
                        processingError: resData.error,
                        formSubmissionSuccessful: true,
                        processing: false
                    });
                }
                if (resData.success) {

                    this.setState({
                        processingError: null,
                        formSubmissionSuccessful: true,
                        processing: false
                    });
                }
            })
            .catch(err => {
                console.log(err);
            });

    };

    validate = (value, validation) => {
        var fieldValue = value;
        var errMessage = "";

        if ('email' in validation[1]) {
            let emailValid = fieldValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            if (!emailValid) {
                errMessage = validation[1].email;
            }
        }

        if ('mobile' in validation[1]) {
            var reMobile = new RegExp("^((61|\\+61)?\\s?)((04[0-9]{2}|4[0-9]{2})\\s?)([0-9]{3}\\s?[0-9]{3}|[0-9]{2}\\s?[0-9]{2}\\s?[0-9]{2})$");
            if (!reMobile.test(fieldValue)) {
                errMessage = validation[1].mobile;
            }
        }

        if ('required' in validation[1]) {
            if (fieldValue.trim().length === 0) {
                errMessage = validation[1].required;
            }
        }

        this.setState({ [validation[0]]: errMessage });
        return (errMessage === "") ? 0 : 1;
    }

    render() {


        const errorMessage = (
            <>
                <Paragraph1>
                    <strong>OPPS! Something went wrong.</strong>
                </Paragraph1>

                <Button type="button" onClick={this.resetForm}>Try Again</Button>
            </>
        );

        let confirmationMessage = (
            <>
                <Paragraph1>
                    Thank you for your enquiry.
                </Paragraph1>
            </>
        );

        if (this.state.processingError) confirmationMessage = errorMessage;

        const processingMessage = (
            <>
                <Paragraph1>
                    Submitting....
                </Paragraph1>
            </>);


        const pageHTML = (

            <form onSubmit={this.submitHandler}>

                <FormControl>
                    <LabelContainer>
                        <Label for="name">Your name</Label>
                    </LabelContainer>
                    <Input
                        type="text"
                        id="name"
                        name="name"
                        onBlur={(e) => this.validate(e.target.value, this.nameValidation)}
                        onChange={this.handleInputChange}
                        ref={this.nameEl}
                        aria-label="Full Name"
                    />
                    <InputError>{this.state.nameError}</InputError>
                </FormControl>


                <FormControl>
                    <LabelContainer>
                        <Label for="mobile">Mobile</Label>
                    </LabelContainer>
                    <Input
                        type="text"
                        id="mobile"
                        name="mobile"
                        onBlur={(e) => this.validate(e.target.value, this.mobileValidation)}
                        onChange={this.handleInputChange}
                        ref={this.mobileEl}
                        aria-label="Mobile"
                    />
                    <InputError>{this.state.mobileError}</InputError>
                </FormControl>


                <FormControl>
                    <LabelContainer>
                        <Label for="email">Your email</Label>
                    </LabelContainer>
                    <Input
                        type="email"
                        id="email"
                        name="email"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$"
                        onBlur={(e) => this.validate(e.target.value, this.emailValidation)}
                        onChange={this.handleInputChange}
                        aria-label="Email"
                    />
                    <InputError>{this.state.emailError}</InputError>
                </FormControl>

                <FormControl>
                    <LabelContainer>
                        <Label for="comment">Comment</Label>
                    </LabelContainer>
                    <TextArea
                        rows="8"
                        cols="40"
                        id="comment"
                        name="comment"
                        ref={this.commentEl}
                        onChange={this.handleInputChange}
                        aria-label="Comment"
                    />
                </FormControl>


                <FormControl>
                    <LabelContainer>
                        <LabelBold for="valuation">Are you selling soon?</LabelBold>
                    </LabelContainer>
                    <p>

                        If you are selling a prestige car, there is now an alternative to accepting
                         a low trade-in price or the hassles of selling privately. Summon can sell 
                         your car for you, for a great price.
                    </p>

                    <div style={{ display: 'flex' }}>
                        <div style={{ marginTop: '5px', marginRight: '10px' }}>
                            <Checkbox
                                type="checkbox"
                                id="valuation"
                                name="valuation"
                                //onBlur={(e) => this.validate(e.target.value, this.regVinValidation)}
                                onChange={this.handleInputChange}
                                aria-label="Request valuation"
                            />
                        </div>
                        <div>
                            Yes, I own a prestige car ($50k+) and would like to request a <strong>free valuation report</strong> from Summon. <br />
                            <div style={{ fontSize: '0.8rem', color: '#333333', marginTop: '10px' }}>A Summon team member will contact you to request details about your car.</div>
                        </div>
                    </div>
                </FormControl>



                <div>
                    <ReCAPTCHA
                        ref={this.recaptchaRef}
                        sitekey="6LcJvbYUAAAAAIMoSZgsxjjb69wAQ9OhaIoND4nC"
                        onChange={this.onChangeCaptcha}
                        theme="light"
                        size="normal"
                    />
                </div>

                <ActionControl>
                    <Button type="submit">Submit</Button>
                    <InputError>{this.state.FormError}</InputError>
                </ActionControl>

            </form>
        )

        return (

            <div style={{ width: '300px',  backgroundColor: '#f8f8f9', padding: '20px' }}>
               
                <Title>Enquire on this car now</Title>

                {this.props.sold && <p style={{ fontSize: '0.8rem', color: '#333333', marginTop: '10px' }}>
                    <strong>This car is sold. </strong> 
                    To enquire on the sale price, 
                    or other similar cars available or coming 
                    soon, please complete the form below</p>}
               
                {!this.state.formSubmissionSuccessful && !this.state.processing && pageHTML}
                {!this.state.formSubmissionSuccessful && this.state.processing && processingMessage}
                {this.state.formSubmissionSuccessful && !this.state.processing && confirmationMessage}
            </div>

        );
    }

}

export default ListingForm;

const Title = styled.h2`
    font-weight: 600;
    font-size: 1.2rem;
    margin-right: 10px;
`

const LabelContainer = styled.div`
    margin-bottom: 0px;
`;

const LabelBold = styled.label`
    font-weight: 600;
    margin-right: 10px;
`;






