import styled from 'styled-components';

const CancelButton = styled.button.attrs(props => ({
  type: props.type || 'button',
  color: props.color || '#3479ae'
}))`
color: ${props => props.color};
outline: none;
border: none; 
background: none;

padding-top: 10px;
text-decoration: none;
display: inline-block;
font-size: 0.9rem;
font-weight: normal;
margin: 0px;


cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`
export default CancelButton;