export default function fbTrack(a, b, c) {
    if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
        fbq(a, b, c);  // eslint-disable-line no-undef
    }
}

//CompleteRegistration
// import fbTrack from './fb-track'
// contactClick = () => fbTrack('track', 'Contact')


/*

 fbTrack('track', 
                'Lead', 
                {content_category: "Price Guide", 
                 content_name: this.props.page, 
                 currency: 'AUD', 
                 value: 50.00}
        );

*/