import React from "react"
import SectionPageMarginGradient from '../Section/SectionPageMarginGradient'

import PageTitle from '../../Type/PageTitle'
import Paragraph1 from '../../Type/Paragraph1'
import Spacer from '../Spacer/Spacer'
import styled from 'styled-components';

const PageHeader = ({ bgColor, title, text, price, odometer, year, location, textColor, marginBottom }) => {
  if (!bgColor) bgColor = "#3479ae";
  if (!textColor) textColor = "white";

  return (
    <>
      <SectionPageMarginGradient marginBottom={marginBottom} >
        <Grid23>
          <div>
            <PageTitle color={textColor}>{title}</PageTitle>
            <Paragraph1 color={textColor}>
              {text}
            </Paragraph1>
            <Spacer height="40px" />
          </div>
          <CarDetails>
            <Data><TH>Price (EGC)</TH> {price}</Data>
            <Data><TH>Odometer</TH> {odometer}</Data>
            <Data><TH>Build Year</TH> {year}</Data>
            <Data><TH>Location</TH> {location}</Data>
          </CarDetails>
        </Grid23>
      </SectionPageMarginGradient>
    </>
  )
}

export default PageHeader

const Grid23 = styled.div`
    display: grid;
    grid-template-columns: 1fr 250px;
    column-gap: 40px;
    
    
    @media (max-width: 800px) {
      justify-content: center;
      grid-template-columns: 100%;
      grid-template-rows: auto auto;
      padding-bottom: 30px;
    }

`;
const CarDetails = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: right; 
    color: white;
    font-size: 1.5rem;
    font-weight: 600;

    @media (max-width: 800px) {
      flex-direction: row;
      justify-content: center;
      
    }

`;

const Data = styled.div`
    width:200px; 
    margin: 3px 0px 3px 0px;
`;

const TH = styled.span`
    color: #aec5db;
    font-size: .9rem;
`;






