import React from 'react';
import info from './info.png';
import styled from 'styled-components';

const Information = ({ action, link, title, type }) => (
        <Container >
            <img src={info} alt="info" style={{position: 'absolute', top: '0px', left: '0px'}}/>
            <div style={{paddingTop: '5px'}}>
                {action} <a href={link}>{title}</a> {type}
            </div>
        </Container>
)

export default Information;

const Container = styled.div`
    position: relative;
    padding-left: 35px;
    margin-top: 2px;
    margin-bottom: 10px;

`;

