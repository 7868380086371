
import styled from 'styled-components'

const Label = styled.label`
  color: black;
  font-size: 0.8rem;
  display: block;
  margin-bottom: 5px;
`
export default Label;

