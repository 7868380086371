import React from 'react';
import bullet from './3dbullet.png';

import styled from 'styled-components';



const Highlight = ({ title, value }) => (
        <Container >
            <img src={bullet} alt="bullet" style={{position: 'absolute', top: '0px', left: '0px'}}/>
            <div>
                <Value>{value}</Value>
                <Title>{title}</Title>
            </div>
        </Container>
)

export default Highlight;

const Container = styled.div`
    position: relative;
    padding-left: 35px;
    margin-bottom: 10px;
    margin-right: 20px;

`;

const Value = styled.div`
    font-weight: 600;
    font-size: .8rem;
    color: #000000;
`;
const Title = styled.div`
    color: #111111;
    font-size: .8rem;
`;